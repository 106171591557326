<template>
  <v-row align="center" justify="center">
    <v-col
      v-for="(image, n) in images"
      :key="n"
      class="d-flex child-flex"
      cols="12" sm="12" md="6"
    >
        <!-- <v-card class="mx-auto" :href="image.link" target="_blank"> -->
        <v-card class="mx-auto">
            <v-img
                height="auto"
                max-height="80vh"
                :src="image.src"
                :lazy-src="image.lazy_src"
                class="grey lighten-2"
            >
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-card-text v-if="image.photographer">
                {{image.photographer}}
            <a class="link" :href="image.link" target="_blank">
                <v-icon v-if="image.link">
                    mdi-open-in-new
                </v-icon>
            </a>
            </v-card-text>
        </v-card>
    </v-col>c
    <!-- <v-row>
        <v-spacer/>
        <v-btn
            outlined
            class="button"
            @click="overlay = !overlay"
        >
        See More
        </v-btn>
        <v-overlay
            :value="overlay"
            @click="overlay = !overlay"
        >
            <v-row>
                <v-spacer/>
            <v-btn
                @click="overlay = false"
                icon
                dark
                large
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-row>
            <Overlay/>
        </v-overlay>
        <v-spacer/>
    </v-row> -->
  </v-row>
</template>
<script>
export default {
    data: () => ({
        absolute: true,
        overlay: false,
        images: [
            {
                src: require('../assets/images/Gallery/finished_barn/compressed/36.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/36-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/compressed/35.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/35-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/main/compressed/01.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/main/01-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/main/compressed/02.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/main/02-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/main/compressed/03.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/main/03-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/main/compressed/04.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/main/04-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/bridal/compressed/01.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/bridal/01-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/bridal/compressed/02.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/bridal/02-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/bridal/compressed/03.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/bridal/03-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/bridal/compressed/04.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/bridal/04-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/groom/compressed/01.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/groom/01-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/groom/compressed/02.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/groom/02-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/groom/compressed/03.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/groom/03-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/groom/compressed/04.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/groom/04-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/bar/compressed/01.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/bar/01-small.jpg')
            },
            {
                src: require('../assets/images/Gallery/finished_barn/bar/compressed/02.jpg'),
                lazy_src: require('../assets/images/Gallery/finished_barn/bar/02-small.jpg')
            },
            // Ahrendt
            {
                src: require('../assets/images/Gallery/ahrendt_wedding/compressed/1.jpg'),
                lazy_src: require('../assets/images/Gallery/ahrendt_wedding/1-small.jpg'),
                photographer: "Bravo Photography",
                link: "https://www.jbrav.com/"
            },
            {
                src: require('../assets/images/Gallery/ahrendt_wedding/compressed/2.jpg'),
                lazy_src: require('../assets/images/Gallery/ahrendt_wedding/2-small.jpg'),
                photographer: "Bravo Photography",
                link: "https://www.jbrav.com/"
            },
            {
                src: require('../assets/images/Gallery/ahrendt_wedding/compressed/3.jpg'),
                lazy_src: require('../assets/images/Gallery/ahrendt_wedding/3-small.jpg'),
                photographer: "Bravo Photography",
                link: "https://www.jbrav.com/"
            },
            {
                src: require('../assets/images/Gallery/ahrendt_wedding/compressed/4.jpg'),
                lazy_src: require('../assets/images/Gallery/ahrendt_wedding/4-small.jpg'),
                photographer: "Bravo Photography",
                link: "https://www.jbrav.com/"
            },
            // Kelsey/Ethan
            {
                src: require('../assets/images/Gallery/kelsey_ethan_wedding/compressed/1.jpg'),
                lazy_src: require('../assets/images/Gallery/kelsey_ethan_wedding/1-small.jpg'),
                photographer: "Erica Jane Photography",
                link: "https://ericajanephotos.com/"
            },
            {
                src: require('../assets/images/Gallery/kelsey_ethan_wedding/compressed/2.jpg'),
                lazy_src: require('../assets/images/Gallery/kelsey_ethan_wedding/2-small.jpg'),
                photographer: "Erica Jane Photography",
                link: "https://ericajanephotos.com/"
            },
            {
                src: require('../assets/images/Gallery/kelsey_ethan_wedding/compressed/3.jpg'),
                lazy_src: require('../assets/images/Gallery/kelsey_ethan_wedding/3-small.jpg'),
                photographer: "Erica Jane Photography",
                link: "https://ericajanephotos.com/"
            },
            {
                src: require('../assets/images/Gallery/kelsey_ethan_wedding/compressed/4.jpg'),
                lazy_src: require('../assets/images/Gallery/kelsey_ethan_wedding/4-small.jpg'),
                photographer: "Erica Jane Photography",
                link: "https://ericajanephotos.com/"
            },
            {
                src: require('../assets/images/Gallery/kelsey_ethan_wedding/compressed/5.jpg'),
                lazy_src: require('../assets/images/Gallery/kelsey_ethan_wedding/5-small.jpg'),
                photographer: "Erica Jane Photography",
                link: "https://ericajanephotos.com/"
            },
            {
                src: require('../assets/images/Gallery/kelsey_ethan_wedding/compressed/6.jpg'),
                lazy_src: require('../assets/images/Gallery/kelsey_ethan_wedding/6-small.jpg'),
                photographer: "Erica Jane Photography",
                link: "https://ericajanephotos.com/"
            },
            // Betsy/Sam
            {
                src: require('../assets/images/Gallery/betsy_sam_wedding/compressed/01.jpg'),
                lazy_src: require('../assets/images/Gallery/betsy_sam_wedding/01-small.jpg'),
                photographer: "Daisy and Lace Photography",
                link: "https://daisyandlacephoto.com/"
            },
            {
                src: require('../assets/images/Gallery/betsy_sam_wedding/compressed/02.jpg'),
                lazy_src: require('../assets/images/Gallery/betsy_sam_wedding/02-small.jpg'),
                photographer: "Daisy and Lace Photography",
                link: "https://daisyandlacephoto.com/"
            },
            {
                src: require('../assets/images/Gallery/betsy_sam_wedding/compressed/03.jpg'),
                lazy_src: require('../assets/images/Gallery/betsy_sam_wedding/03-small.jpg'),
                photographer: "Daisy and Lace Photography",
                link: "https://daisyandlacephoto.com/"
            },
            {
                src: require('../assets/images/Gallery/betsy_sam_wedding/compressed/04.jpg'),
                lazy_src: require('../assets/images/Gallery/betsy_sam_wedding/04-small.jpg'),
                photographer: "Daisy and Lace Photography",
                link: "https://daisyandlacephoto.com/"
            },
            // Lydia Schruggs
            {
                src: require('../assets/images/Gallery/lydia_schruggs_wedding/compressed/1.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "Paige LeAnn",
                link: "https://paigeleannphotography.mypixieset.com"
            },
            {
                src: require('../assets/images/Gallery/lydia_schruggs_wedding/compressed/2.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "Paige LeAnn",
                link: "https://paigeleannphotography.mypixieset.com"
            },
            {
                src: require('../assets/images/Gallery/lydia_schruggs_wedding/compressed/3.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "Paige LeAnn",
                link: "https://paigeleannphotography.mypixieset.com"
            },
            {
                src: require('../assets/images/Gallery/lydia_schruggs_wedding/compressed/4.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "Paige LeAnn",
                link: "https://paigeleannphotography.mypixieset.com"
            },
            {
                src: require('../assets/images/Gallery/lydia_schruggs_wedding/compressed/5.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "Paige LeAnn",
                link: "https://paigeleannphotography.mypixieset.com"
            },
            {
                src: require('../assets/images/Gallery/lydia_schruggs_wedding/compressed/6.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "Paige LeAnn",
                link: "https://paigeleannphotography.mypixieset.com"
            },
            // Shafers
            {
                src: require('../assets/images/Gallery/shafer_wedding/compressed/1.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "RCC Photography",
                link: "https://rcc-photography.pixieset.com/"
            },
            {
                src: require('../assets/images/Gallery/shafer_wedding/compressed/2.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "RCC Photography",
                link: "https://rcc-photography.pixieset.com/"
            },
            {
                src: require('../assets/images/Gallery/shafer_wedding/compressed/3.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "RCC Photography",
                link: "https://rcc-photography.pixieset.com/"
            },
            {
                src: require('../assets/images/Gallery/shafer_wedding/compressed/4.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "RCC Photography",
                link: "https://rcc-photography.pixieset.com/"
            },
            {
                src: require('../assets/images/Gallery/shafer_wedding/compressed/5.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "RCC Photography",
                link: "https://rcc-photography.pixieset.com/"
            },
            {
                src: require('../assets/images/Gallery/shafer_wedding/compressed/6.jpg'),
                lazy_src: require('../assets/images/Gallery/24-small.jpg'),
                photographer: "RCC Photography",
                link: "https://rcc-photography.pixieset.com/"
            },
            // Sioux Falls Business
            {
                src: require('../assets/images/Gallery/compressed/34.jpg'),
                lazy_src: require('../assets/images/Gallery/34-small.jpg'),
                photographer: "SiouxFalls Business",
                link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            },
            {
                src: require('../assets/images/Gallery/compressed/33.jpg'),
                lazy_src: require('../assets/images/Gallery/33-small.jpg'),
                photographer: "SiouxFalls Business",
                link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            },
            // {
            //     src: require('../assets/images/Gallery/compressed/32.jpg'),
            //     lazy_src: require('../assets/images/Gallery/32-small.jpg'),
            //     photographer: "SiouxFalls Business",
            //     link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            // },
            // {
            //     src: require('../assets/images/Gallery/compressed/31.jpg'),
            //     lazy_src: require('../assets/images/Gallery/31-small.jpg'),
            //     photographer: "SiouxFalls Business",
            //     link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            // },
            // {
            //     src: require('../assets/images/Gallery/compressed/30.jpg'),
            //     lazy_src: require('../assets/images/Gallery/30-small.jpg'),
            //     photographer: "SiouxFalls Business",
            //     link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            // },
            // {
            //     src: require('../assets/images/Gallery/compressed/29.jpg'),
            //     lazy_src: require('../assets/images/Gallery/29-small.jpg'),
            //     photographer: "SiouxFalls Business",
            //     link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            // },
            {
                src: require('../assets/images/Gallery/compressed/28.jpg'),
                lazy_src: require('../assets/images/Gallery/28-small.jpg'),
                photographer: "SiouxFalls Business",
                link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            },
            {
                src: require('../assets/images/Gallery/compressed/27.jpg'),
                lazy_src: require('../assets/images/Gallery/27-small.jpg'),
                photographer: "SiouxFalls Business",
                link: "https://www.siouxfalls.business/new-east-side-event-barn-offers-countryside-setting-with-view-of-river/?fbclid=IwAR3BGBHaGtqY6L35vSZNQc5jiPph6aj47xTvBCLr6mMJOjfaXxqKgAnaryo"
            },
            // {
            //     src: require('../assets/images/Gallery/compressed/26.jpg'),
            //     lazy_src: require('../assets/images/Gallery/26-small.jpg')
            // },
            // {
            //     src: require('../assets/images/Gallery/compressed/25.jpg'),
            //     lazy_src: require('../assets/images/Gallery/25-small.jpg')
            // },
            // {
            //     src: require('../assets/images/Gallery/compressed/24.jpg'),
            //     lazy_src: require('../assets/images/Gallery/24-small.jpg')
            // },
        ],
    }),
    components: {
        // Overlay: () => import('../components/Overlay.vue')
    }
}
</script>
<style scoped>
v-img:hover {
  width: 500px;
  height: 200px;
}
.button {
    align-self: center;
    font-style: italic;
    border-radius: 0;
}
.one-line {
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>